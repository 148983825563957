import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"

import DevicesIcon from "@material-ui/icons/Devices"
import CreateIcon from "@material-ui/icons/Create"
import CloudDoneIcon from "@material-ui/icons/CloudDone"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Doctors Website Design</h2>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={11}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            Having to schedule a medical exam is hard enough. With our
            innovative design ideas, we make every visitor feel comfortable
            while browsing your website. We offer healthcare websites for
            doctors and dentists while using modern, reliable and user-friendly
            platforms. Our web design agency provides:
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={11} sm={10} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. We don't use website templates and create everything from scratch. Our custom website approach provides doctors with professional websites."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description=" 24/7 Hosting Services – We make sure that your website is available 24 hours a day, 7 days a week. We also help in securing your desired domain and connecting it to your site. The platforms we use are reputable, safe, and user-friendly."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="Mobile, Tablet, and Desktop Optimization – Statistically, mobile-optimized sites have higher chances for catching the interest of online visitors. We optimize your website for all types of electronic usage, making sure your website is available for every type of visitor. "
              icon={DevicesIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
