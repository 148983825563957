import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "doctors-web-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid
            item
            md={5}
            sm={10}
            lg={5}
            xs={10}
            style={{ paddingBottom: "60px" }}
          >
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="doctors-web-design"
            />
          </Grid>
          <Grid item lg={4} md={10} sm={11} xs={11}>
            <h3 className={classes.title}>Launch with Confidence</h3>
            <h6 className={classes.description}>
              The Importance of Web Design
            </h6>
            <p className={classes.description}>
              Web design has a crucial role in the online presence of a company.
              It can also determine the amount of calls you get in the office.
              An outdated front end or a lack of mobile optimization can
              influence web visitors, redirecting them toward your competition.
              A well-developed website for your medical practice can separate
              you from your competition and get you patients.
            </p>
            <p className={classes.description}>
              Statistics show how online consumers base their decisions on a
              website’s aesthetics and design. Consumers demand easy-access
              information, contact forms and modern interfaces.
            </p>
            <p className={classes.description}>
              At Atlas Agency, we cover every aspect of web development. We are
              up to date on the latest medical website design practices, perform
              thorough keyword analytics, and relevant market research. Our team
              makes sure that your website is the best representation of your
              medical office.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
