import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  sectionDiv: {
    padding: "0px 0px 60px 0px",
  },
  link: {
    color: "#273746",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            These days, traditional marketing isn’t enough to reach an audience.
            Web design is a crucial element of a strong online presence. At
            Atlas Agency, we have years of experience, access to modern
            platforms, and effective strategies. We host on reliable platforms
            and also offer customizable design solutions. With us, you will
            launch your new doctor website with confidence. We also provide {""}
            <Link
              className={classes.link}
              to="/seo-company-toronto"
              href="https://atlasagency.ca/seo-company-toronto"
            >
              SEO services
            </Link>
            , making sure your website not only looks good, but also ranks
            higher on Google’s search engine results.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
